import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-app-back-header',
  templateUrl: './app-back-header.component.html',
  styleUrls: ['./app-back-header.component.scss'],
})
export class AppBackHeaderComponent implements OnInit {

  constructor(public modal: ModalController) { }

  ngOnInit() {}

  closeModal() {
    this.modal.dismiss();
  }
}
