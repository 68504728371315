import { Component, Input, OnInit } from '@angular/core';
import { FirebaseService, User } from 'src/app/services/firebase.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connect-chat-modal',
  templateUrl: './connect-chat-modal.component.html',
  styleUrls: ['./connect-chat-modal.component.scss'],
})
export class ConnectChatModalComponent implements OnInit {
  @Input() person: User;

  form = new FormGroup({});
  model = { message: '' };
  fields: FormlyFieldConfig[] = [
    {
      key: 'message',
      type: 'textarea',
      templateOptions: {
        label: 'Your message',
        placeholder: '',
        required: true,
      }
    }
  ];

  constructor(private modalController: ModalController, public fs: FirebaseService, public router: Router) { }

  ngOnInit() { }

  async send() {
    if (this.model.message.length > 0) {
      await this.fs.startNewChat([this.person.id], this.model.message);
      this.modalController.dismiss();
      await this.router.navigate(['/', 'connect', 'chat']);
      location.reload();
    }
  }
}
