import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FirebaseService, User, ConnectStatus } from 'src/app/services/firebase.service';
import { ConnectChatModalComponent } from '../connect-chat-modal/connect-chat-modal.component';

@Component({
  selector: 'app-connect-profile-modal',
  templateUrl: './connect-profile-modal.component.html',
  styleUrls: ['./connect-profile-modal.component.scss'],
})
export class ConnectProfileModalComponent implements OnInit {
  @Input() person: User;
  connectStatus: ConnectStatus;
  loading = true;
  constructor(public fs: FirebaseService, public modalController: ModalController) { }
  async ngOnInit() {
    this.connectStatus = await this.fs.getConnectStatus(this.person.id);
    this.loading = false;
  }
  async requestConnect() {
    this.connectStatus = await this.fs.requestConnect(this.person.id);
  }
  async chat() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: ConnectChatModalComponent,
      cssClass: 'ticketModal',
      componentProps: {
        person: this.person
      }
    });
    return await modal.present();
  }
}
