import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MenuModalComponent } from '../menu-modal/menu-modal.component';

@Component({
  selector: 'app-app-header-menu',
  templateUrl: './app-header-menu.component.html',
  styleUrls: ['./app-header-menu.component.scss'],
})
export class AppHeaderMenuComponent {

  constructor(private modalController: ModalController, public firebaseService: FirebaseService) {
  }

  async openMenu() {
    // open the menu
    const modal = await this.modalController.create({
      component: MenuModalComponent,
      cssClass: 'menuModal'
    });
    return await modal.present();
  }
}
