import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss'],
})
export class PdfModalComponent implements OnInit, OnDestroy {

  loading = true;
  pdf: string;
  pdfSub: Subscription;
  constructor(public fs: FirebaseService) { }

  ngOnInit() {
    this.pdfSub = this.fs.getFSDoc<{pdf: string}>('/v2/conference').subscribe(doc => {
      if (doc) {
        this.pdf = doc.pdf;
      }
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.pdfSub?.unsubscribe();
  }
}
