import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from './services/firebase.service';
import { WelcomeModalComponent } from './shared/welcome-modal/welcome-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  loading = true;
  loggedIn = false;

  constructor(public firebase: FirebaseService, private router: Router, public modal: ModalController) {
    setTimeout(() => {
      if (this.router.url === '/') {
        this.router.navigate(['/', 'home'])
      }
      this.loading = false;
    }, 2000);
    this.firebase.auth.authState.subscribe(auth => {
      if (auth) {
        this.loggedIn = true;
        const sub = this.firebase.ourUserRecord.subscribe(async usr => {
          if (!usr) {
            const modal = await this.modal.create({
              component: WelcomeModalComponent,
              cssClass: 'ticketModal'
            });
            return await modal.present();
          } else {
            sub.unsubscribe();
          }
        })
      } else {
        this.loggedIn = false;
      }
    });
  }
}
