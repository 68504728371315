import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-modal',
  templateUrl: './menu-modal.component.html',
  styleUrls: ['./menu-modal.component.scss'],
})
export class MenuModalComponent implements OnInit, OnDestroy {

  showAdmin = false;

  adminSub: Subscription;

  constructor(@Inject(DOCUMENT) private document: Document, public router: Router, public modalController: ModalController, public firebase: FirebaseService) { }

  ngOnInit() {
    this.adminSub = this.firebase.isAdmin.subscribe(adm => {
      if (adm) {
        this.showAdmin = true;
      }
    });
  }

  ngOnDestroy() {
    this.adminSub?.unsubscribe();
  }

  close() {
    this.modalController.dismiss();
  }

  navigate(destination: string) {
    this.router.navigate(['/', destination]);
    this.modalController.dismiss();
  }

  support() {
    this.document.location.href = 'https://aceleaders.org'
  }

  logout() {
    this.firebase.auth.signOut();
  }
}
