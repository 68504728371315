import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from '@angular/fire/auth'
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';
import { Observable, Observer } from 'rxjs';

export enum MembershipType {
  INDIVIDUAL,
  BUSINESS
}

export interface Admin {
  admin: boolean;
  banned: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface Feed {
  date: number;
  event: string;
}

export interface User {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  id: string;
  image: string;
  employer: string;
  title: string;
  industry: string;
  bio: string;
  aboutYouVisible: boolean;
  emailVisible: boolean;
  occupationVisible: boolean;
  phoneVisible: boolean;
  publicProfile: boolean;
}

export interface Connection {
  id: string;
  connectionId: string;
  date: number;
}

export enum ConnectStatus {
  NONE,
  PENDING,
  CONNECTED
}

export interface Chat {
  topic?: string;
  id: string;
  members: string[];
  lastUpdated: number;
  lastMessage: string;
}

export interface ChatMessage {
  message: string;
  date: number;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public ourUserRecord: Observable<User>;
  public isAdmin: Observable<Admin>;
  public user: any;

  constructor(public firestore: AngularFirestore, public auth: AngularFireAuth) {
    this.auth.user.subscribe(usr => {
      this.user = usr;
      if (usr) {
        try {
          this.ourUserRecord = this.getFSDoc<User>(`/v2/members/users/${usr.uid}`);
          this.isAdmin = this.getFSDoc<Admin>(`/admin/${usr.email}`);
        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  public loginWithGoogle() {
    const prov = new GoogleAuthProvider();
    prov.addScope('profile');
    prov.addScope('email');
    this.auth.signInWithPopup(prov);
  }

  public loginWithFacebook() {
    const prov = new FacebookAuthProvider();
    prov.addScope('profile');
    prov.addScope('email');
    this.auth.signInWithPopup(prov);
  }

  public loginWithMicrosoft() {
    // temporary workaround due to typing error.
    const FixedOAuthProvider = OAuthProvider as { new(providerId: string): OAuthProvider };
    const prov = new FixedOAuthProvider('microsoft.com');

    this.auth.signInWithPopup(prov);
  }

  public getFSDoc<T>(destination: string): Observable<T> {
    return this.firestore.doc<T>(destination).valueChanges();
  }

  public getFSCol<T>(destination: string): Observable<Array<T>> {
    return this.firestore.collection<T>(destination).valueChanges();
  }

  public async saveUser(userdoc: User): Promise<void> {
    await this.firestore.doc(`/v2/members/users/${this.user.uid}`).set(userdoc);
  }

  public getTickets(): Observable<any> {
    return this.firestore.collection<any>(`/v2/members/users/${this.user.uid}/tickets`).valueChanges();
  }

  public getPurchases(): Observable<any> {
    return this.firestore.collection<any>(`/v2/members/users/${this.user.uid}/purchases`).valueChanges();
  }

  public async getConnections(user: string): Promise<User[]> {
    const connections = await this.firestore.collection<Connection>('/v2/connect/connections').ref.where('id', '==', user).get();
    if (connections.docs.length == 0) return [];
    const users = await this.firestore.collection<User>('/v2/members/users').ref.where('id', 'in', connections.docs.map(x => x.data().connectionId)).get();
    return users.docs.map(x => x.data());
  }

  public async getConnectionRequests(): Promise<User[]> {
    const requests = await this.firestore.collection<Connection>('/v2/connect/connectRequests').ref.where('id', '==', this.user.uid).get();
    if (requests.docs.length == 0) return [];
    const users = await this.firestore.collection<User>('/v2/members/users').ref.where('id', 'in', requests.docs.map(x => x.data().connectionId)).get();
    return users.docs.map(x => x.data());
  }

  public async getConnectStatus(otherUserId: string): Promise<ConnectStatus> {
    if (this.user.uid == otherUserId) return ConnectStatus.NONE;
    const alreadyConnected = await this.firestore.collection<Connection>('/v2/connect/connections').ref.where('id', '==', otherUserId).where('connectionId', '==', this.user.uid).get();
    if (alreadyConnected.size > 0) {
      // already connected
      return ConnectStatus.CONNECTED;
    }
    const connectRequested = await this.firestore.collection<Connection>('/v2/connect/connectRequests').ref.where('id', '==', otherUserId).where('connectionId', '==', this.user.uid).get();
    if (connectRequested.size > 0) {
      // already requested
      return ConnectStatus.PENDING;
    } else {
      return ConnectStatus.NONE;
    }
  }

  public async requestConnect(otherUserId: string): Promise<ConnectStatus> {
    if (this.user.uid == otherUserId) return ConnectStatus.NONE;
    // check if the other person has requested to connect 
    const connectRequested = await this.firestore.collection<Connection>('/v2/connect/connectRequests').ref.where('id', '==', this.user.uid).where('connectionId', '==', otherUserId).get();
    console.log(connectRequested.size);
    if (connectRequested.size > 0) {
      console.log('completing connection for both parties');
      // already requested, complete connection for both parties
      await this.firestore.collection<Connection>('/v2/connect/connections').doc().set({ id: this.user.uid, connectionId: otherUserId, date: Date.now() });
      await this.firestore.collection<Connection>('/v2/connect/connections').doc().set({ id: otherUserId, connectionId: this.user.uid, date: Date.now() });
      const toDelete = await this.firestore.collection<Connection>('/v2/connect/connectRequests').ref.where('id', '==', this.user.uid).where('connectionId', '==', otherUserId).get();
      toDelete.docs.map(x => x.ref.delete());
      return ConnectStatus.CONNECTED;
    } else {
      console.log('first request, return pending')
      // first request, return pending
      await this.firestore.collection<Connection>('/v2/connect/connectRequests').doc().set({ id: otherUserId, connectionId: this.user.uid, date: Date.now() });
      return ConnectStatus.PENDING;
    }
  }

  public async discoverPeople(): Promise<User[]> {
    const people = await this.firestore.collection<User>('/v2/members/users').ref.where('publicProfile', '==', true).get();
    return people.docs.map(x => x.id !== this.user.uid ? x.data() : null).filter(n => n);
  }

  public async startNewChat(otherUserIds: string[], message: string, topic?: string): Promise<void> {
    const doc = await this.firestore.collection<Chat>('/v2/connect/chat').doc();
    doc.set({ id: doc.ref.id, members: otherUserIds.concat([this.user.uid]), lastMessage: message, lastUpdated: Date.now() });
    await this.firestore.collection<ChatMessage>(`/v2/connect/chat/${doc.ref.id}/messages`).doc().set({ message: message, date: Date.now(), id: this.user.uid });
  }

  public async sendChatMessage(chatId: string, message: string): Promise<void> {
    await this.firestore.collection<ChatMessage>(`/v2/connect/chat/${chatId}/messages`).doc().set({ message: message, date: Date.now(), id: this.user.uid });
    await this.firestore.doc<Chat>(`/v2/connect/chat/${chatId}`).update({ lastMessage: message, lastUpdated: Date.now() });
  }

  public async deleteChat(chatId: string): Promise<void> {
    if (chatId == '') return;
    await this.firestore.doc<Chat>(`/v2/connect/chat/${chatId}`).delete();
  }

  public async getChatMessages(): Promise<Observable<Array<[User[], ChatMessage[], Chat]>>> {
    const dms = await this.firestore.collection<Chat>('/v2/connect/chat').ref.where('members', 'array-contains', this.user.uid).get();
    return new Observable((obs) => {
      let retvals: Array<[User[], ChatMessage[], Chat]> = [];
      for (const chat of dms.docs) {
        this.firestore.doc<Chat>(`/v2/connect/chat/${chat.id}`).valueChanges().subscribe(cht => {
          this.firestore.collection<User>(`/v2/members/users`).ref.where('id', 'in', chat.data().members).get().then(users => {
            this.firestore.collection<ChatMessage>(`/v2/connect/chat/${chat.id}/messages`).ref.get().then(msgs => {
              retvals = retvals.filter(x => x[2].id !== cht.id);
              retvals.push([users.docs.map(x => x.data()), msgs.docs.map(x => x.data()), chat.data()]);
              retvals.sort((x, y) => y[1][(y[1].length - 1)].date - x[1][(x[1].length - 1)].date);
              obs.next(retvals);
            })
          });
        })
      }
    });
  }

  public async createFeedItem(event: string): Promise<void> {
    await this.firestore.collection<Feed>('/v2/connect/feed').doc().set({ event: event, date: Date.now() })
    await this.firestore.doc('/v2/connect').update({ update: 1 });
  }

  public getConnectFeed(): Observable<Feed[]> {
    return new Observable((obs: Observer<Feed[]>) => {
      this.firestore.doc('/v2/connect').valueChanges().subscribe(() => {
        this.firestore.collection<Feed>('/v2/connect/feed').ref.orderBy('date', 'desc').limit(25).get().then(async feed => {
          obs.next(feed.docs.map(x => x.data()));
          obs.complete(); // even though we mark complete, it can still be updated with next.
        });
      });
    });
  }
}
