import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface wp_posts {
  id: number;
  date_gmt: string;
  link: string;
  content: {
    protected: boolean;
    rendered: string;
  }
  title: {
    rendered: string
  }
  _embedded: {
    'wp:featuredmedia': Array<wp_featured_media>;
  }
}

interface wp_featured_media {
  id: number;
  date: string;
  type: string;         // filter for 'attachment'
  media_type: string;   // expect image
  mime_type: string;    // expect image/png
  media_details: any;   // this data structure is a mess
  source_url: string;   // this is our image URL
  _links: any;          // another messy data structure
}

export interface wp_event {
  id: number;
  date: string;
  url: string;
  title: string;
  description: string;
  image: {
    url: string;
  }
  all_day: boolean;
  start_date: string;
  start_date_details: {
    year: string;
    month: string;
    day: string;
    hour: string;
    minutes: string;
  }
  end_date: string;
  end_date_details: {
    year: string;
    month: string;
    day: string;
    hour: string;
    minutes: string;
  }
  timezone_abbr: string;
  venue: {
    id: number;
    status: string;
    date: string;
    url: string;
    venue: string;
    address: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    phone: string;
    website: string;
    stateprovince: string;
    show_map: boolean;
    show_map_link: boolean;
  }
}

export interface Membership {
  ID: number,
  id: number,
  subscription_id: number,
  name: string; // 'Individual | Business'
  description: string;
  confirmation: string;
  expiration_number: number;
  expiration_period: string;
  allow_signups: number;
  initial_payment: number;
  billing_amount: number;
  cycle_number: number;
  cycle_period: string;
  billing_limit: number;
  trial_amount: number;
  trial_limit: number;
  code_id: number;
  startdate: number;
  enddate: number | null;
}

export interface Ticket {
  32: string;
  currency: string;
  payment_status: string;
  payment_date: string;
  payment_amount: string;
  payment_method: string;
  transaction_id: string;
  is_fulfilled: string; // 0 or 1
  created_by: string;
  transaction_type: string; // should be: "1"
  status: string; // expect "active"
  2.3: string // firstName
  2.6: string // lastName
  45.1: string // meal type: expect "Vegetarian" else "Non-Vegetarian"
  // many many more fields go here
}

@Injectable({
  providedIn: 'root'
})
export class WebFeedService {

  constructor(private http: HttpClient) { }

  public async getPosts(): Promise<Array<wp_posts>> {
    const posts = await this.http.get('https://aceleaders.org/wp-json/wp/v2/posts?_embed').toPromise();
    return posts as Array<wp_posts>;
  }

  public async getEvents(): Promise<Array<wp_event>> {
    const events: any = await this.http.get('https://aceleaders.org/wp-json/tribe/events/v1/events?_embed').toPromise();
    return events.events as Array<wp_event>;
  }

  public async getMembershipStatus(email: string): Promise<Membership | false> {
    const member = await this.http.get('https://aceleaders.org/wp-json/pmpro/v1/get_membership_level_for_user', { headers: { Authorization: 'Basic QUNFTCBBZG1pbjphQ1dYIHBDMGIga3pieCBRMVJuIExTU1UgYlBsag==' }, params: { email } }).toPromise();
    if (member) {
      return member as Membership;
    } else {
      return false;
    }
  }

  public async getTickets(email: string): Promise<Ticket[] | false> {
    const ticket: any = await this.http.get(`https://aceleaders.org/wp-json/gf/v2/entries?form_ids=4&search={"field_filters":[{"key":"32","value":"${email}"}]}`, { headers: { Authorization: 'Basic QUNFTCBBZG1pbjphQ1dYIHBDMGIga3pieCBRMVJuIExTU1UgYlBsag==' } }).toPromise();
    if (ticket) {
      return ticket.entries as Ticket[];
    } else {
      return false;
    }
  }

  public async getTicketByID(ticketId: number): Promise<Ticket | false> {
    const ticket: any = await this.http.get(`https://aceleaders.org/wp-json/gf/v2/entries?form_ids=4&search={"field_filters":[{"key":"id","value":${ticketId}}]}`, { headers: { Authorization: 'Basic QUNFTCBBZG1pbjphQ1dYIHBDMGIga3pieCBRMVJuIExTU1UgYlBsag==' } }).toPromise();
    if (ticket) {
      return ticket.entries[0] as Ticket;
    } else {
      return false;
    }
  }
}
