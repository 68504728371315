import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Chat, ChatMessage, FirebaseService, User } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-connect-message-modal',
  templateUrl: './connect-message-modal.component.html',
  styleUrls: ['./connect-message-modal.component.scss'],
})
export class ConnectMessageModalComponent implements OnInit, OnDestroy {

  @Input() chat: Chat;

  messages: [User[], ChatMessage[], Chat] = [[], [], null];
  msgSub: Subscription;
  constructor(public fs: FirebaseService, public modalController: ModalController) { }

  async ngOnInit() {
    console.log('opening ', this.chat);
    setTimeout(() => {
      document.getElementById("top").scrollIntoView({ behavior: "smooth" });
    }, 1200);
    this.msgSub = (await this.fs.getChatMessages()).subscribe(msgs => {
      this.messages = msgs.filter(x => x[2].id == this.chat.id)[0];
      this.messages[1].sort((a, b) => b.date - a.date);
      document.getElementById("top").scrollIntoView({ behavior: "smooth" });
    })
  }

  ngOnDestroy() {
    this.msgSub?.unsubscribe();
  }

  notYou(usrs: User[]): User[] {
    return usrs.map(x => { if (x.id !== this.fs.user.uid) { return x } }).filter(n => n);
  }

  async closeModal() {
    this.msgSub?.unsubscribe();
    this.messages = [[], [], null];
    await this.modalController.dismiss();
  }

  async sendMessage(keycode: number, element: any) {
    if (keycode == 13) {
      if (!this.messages[2]) return;
      if (element.value.length == 0) return;
      const msg = element.value;
      element.value = '';
      await this.fs.sendChatMessage(this.messages[2].id, msg);
    }
  }
}
