import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent implements OnInit {

  form = new FormGroup({});
  model = { email: '', firstName: '', lastName: '', phone: '', id: '', image: '', employer: '', title: '', industry: '', bio: '', aboutYouVisible: false, emailVisible: false, occupationVisible: false, phoneVisible: false, publicProfile: false };
  fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email address',
        placeholder: 'Enter email',
        required: true,
      }
    },
    {
      key: 'firstName',
      type: 'input',
      templateOptions: {
        label: 'First Name',
        placeholder: 'Enter your first name',
        required: true,
      }
    },
    {
      key: 'lastName',
      type: 'input',
      templateOptions: {
        label: 'Last Name',
        placeholder: 'Enter your last name',
        required: true,
      }
    },
    {
      key: 'phone',
      type: 'input',
      templateOptions: {
        label: 'Phone Number',
        placeholder: 'Enter your phone number',
        required: true,
      }
    }
  ];

  constructor(public firebase: FirebaseService, public modal: ModalController) { }

  ngOnInit() { }

  accept() {
    // TODO: PERFORM CHECKS HERE
    this.model.id = this.firebase.user.uid;
    this.firebase.saveUser(this.model);
    this.modal.dismiss();
  }

}
