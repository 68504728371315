import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfModalComponent } from './pdf-modal/pdf-modal.component';
import { AppHeaderMenuComponent } from './app-header-menu/app-header-menu.component';
import { AppFooterMenuComponent } from './app-footer-menu/app-footer-menu.component';
import { RouterModule } from '@angular/router';
import { MenuModalComponent } from './menu-modal/menu-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { TicketModalComponent } from './ticket-modal/ticket-modal.component';
import { AppBackHeaderComponent } from './app-back-header/app-back-header.component';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConnectProfileModalComponent } from './connect-profile-modal/connect-profile-modal.component';
import { ConnectChatModalComponent } from './connect-chat-modal/connect-chat-modal.component';
import { ConnectMessageModalComponent } from './connect-message-modal/connect-message-modal.component';


@NgModule({
  declarations: [
    PdfModalComponent,
    AppHeaderMenuComponent,
    AppFooterMenuComponent,
    MenuModalComponent,
    TicketModalComponent,
    AppBackHeaderComponent,
    WelcomeModalComponent,
    ConnectProfileModalComponent,
    ConnectChatModalComponent,
    ConnectMessageModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatToggleModule,
    PdfViewerModule
  ],
  exports: [
    PdfModalComponent,
    AppHeaderMenuComponent,
    AppFooterMenuComponent,
    MenuModalComponent,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatToggleModule,
    TicketModalComponent,
    AppBackHeaderComponent,
    WelcomeModalComponent,
    PdfViewerModule,
    ConnectProfileModalComponent,
    ConnectChatModalComponent,
    ConnectMessageModalComponent
  ]
})
export class SharedModule { }
