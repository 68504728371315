import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-footer-menu',
  templateUrl: './app-footer-menu.component.html',
  styleUrls: ['./app-footer-menu.component.scss'],
})
export class AppFooterMenuComponent implements OnInit {
  @Input() selectedTab = undefined;
  @Input() connectMessagesCount = 0;

  constructor(public router: Router) { }

  ngOnInit() {}

}
